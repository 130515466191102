var _a;
import { __assign, __rest } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useSpring, animated } from '@react-spring/web';
import { Portal } from './portal';
import Mask from './mask';
import isNumber from 'lodash/isNumber';
import { useInnerVisible } from '@/utils/use-inner-visible';
import { useLockScroll } from '@/utils/use-lock-scroll';
import $i18n from 'panda-i18n';
var isRTL = (_a = $i18n.isRTL) === null || _a === void 0 ? void 0 : _a.call($i18n);
var classNamePrefix = 'cn-ui-m-modal';
export var CnModal = function (props) {
    var _a;
    var _b = props.visible, visibleProps = _b === void 0 ? false : _b, container = props.container, _c = props.disablePortal, disablePortal = _c === void 0 ? false : _c, _d = props.bgImg, bgImg = _d === void 0 ? '' : _d, _e = props.placement, placement = _e === void 0 ? 'center' : _e, _f = props.className, className = _f === void 0 ? '' : _f, _g = props.maskClassName, maskClassName = _g === void 0 ? '' : _g, _h = props.containerClassName, containerClassName = _h === void 0 ? '' : _h, _j = props.containerStyle, containerStyle = _j === void 0 ? {} : _j, _k = props.showMask, showMask = _k === void 0 ? true : _k, _l = props.maskClosable, maskClosable = _l === void 0 ? true : _l, _m = props.onMaskClick, onMaskClick = _m === void 0 ? function () { } : _m, _o = props.onClose, onClose = _o === void 0 ? function () { } : _o, _p = props.onVisibleChange, onVisibleChange = _p === void 0 ? function () { } : _p, children = props.children, zIndex = props.zIndex, _q = props.style, styleProp = _q === void 0 ? {} : _q, others = __rest(props, ["visible", "container", "disablePortal", "bgImg", "placement", "className", "maskClassName", "containerClassName", "containerStyle", "showMask", "maskClosable", "onMaskClick", "onClose", "onVisibleChange", "children", "zIndex", "style"]);
    var _r = useState(props.visible), visible = _r[0], setVisible = _r[1];
    useEffect(function () {
        if (visible && !visibleProps) {
            var t1_1 = setTimeout(function () { return setVisible(false); }, 300);
            return function () { return clearTimeout(t1_1); };
        }
        setVisible(visibleProps);
        onVisibleChange(visibleProps);
        return function () { };
    }, [visibleProps]);
    var centerAnimationStyle = useSpring({
        scale: visibleProps ? 1 : 0.8,
        opacity: visibleProps ? 1 : 0,
        config: {
            mass: 1.2,
            tension: 200,
            friction: 25,
            clamp: true,
        },
    });
    var percent = useSpring({
        percent: visibleProps ? 0 : 100,
        config: {
            precision: 0.1,
            mass: 0.4,
            tension: 300,
            friction: 30,
        },
    }).percent;
    var nonCenterAnimationStyle = {
        transform: percent.to(function (v) {
            if (placement === 'bottom') {
                return "translate(0, ".concat(v, "%)");
            }
            if (placement === 'top') {
                return "translate(0, -".concat(v, "%)");
            }
            if (placement === 'left') {
                return isRTL ? "translate(".concat(v, "%, 0)") : "translate(-".concat(v, "%, 0)");
            }
            if (placement === 'right') {
                return isRTL ? "translate(-".concat(v, "%, 0)") : "translate(".concat(v, "%, 0)");
            }
            return 'none';
        }),
    };
    var animationStyle = placement === 'center' ? centerAnimationStyle : nonCenterAnimationStyle;
    var containerRef = useRef(null);
    useLockScroll(containerRef, !!visible);
    var maskVisible = useInnerVisible(!!(visible && visibleProps));
    var contentNode = (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, className, "".concat(classNamePrefix), "".concat(classNamePrefix, "--").concat(placement)), style: isNumber(zIndex) ? { zIndex: zIndex } : {}, 
        // 阻止事件冒泡，避免在 CnDrawer 中被嵌套的时候，父元素 onClick 事件被触发的问题
        onClick: function (e) { return e.stopPropagation(); }, "data-testid": "cn-modal-content" },
        showMask ? (React.createElement(Mask, { className: maskClassName, visible: maskVisible, onClick: function (e) {
                onMaskClick === null || onMaskClick === void 0 ? void 0 : onMaskClick(e);
                if (maskClosable && onClose) {
                    onClose('mask', e);
                    onVisibleChange(false, 'mask');
                }
            } })) : null,
        React.createElement(animated.div, __assign({}, others, { ref: containerRef, className: classNames(className, containerClassName, "".concat(classNamePrefix, "-container"), "".concat(classNamePrefix, "-container--").concat(placement), (_a = {},
                _a["".concat(classNamePrefix, "-container--").concat(placement, "-active")] = visibleProps,
                _a["".concat(classNamePrefix, "-container--").concat(placement, "-inactive")] = !visibleProps,
                _a)), style: __assign(__assign(__assign(__assign({}, styleProp), containerStyle), { background: bgImg ? "url(".concat(bgImg, ")") : '', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }), animationStyle), "data-testid": "cn-modal-container" }), children)));
    if (!visible)
        return null;
    if (disablePortal)
        return contentNode;
    return React.createElement(Portal, { container: container }, contentNode);
};
CnModal.displayName = 'CnModal';
