import { __assign, __rest } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { mergeProps } from '@/utils/with-default-props';
import { isUndef } from '@/utils/func';
// get checked state
var getCheckedState = function (props) {
    return isUndef(props.checked) ? !!props.defaultChecked : !!props.checked;
};
var defaultProps = {
    size: 'medium',
    disabled: false,
    readOnly: false,
    loading: false,
};
var Switch = function (props) {
    var _a;
    var _b = mergeProps(defaultProps, props), _c = _b.prefix, prefix = _c === void 0 ? 'cn-ui-m-' : _c, className = _b.className, size = _b.size, disabled = _b.disabled, readOnly = _b.readOnly, loading = _b.loading, style = _b.style, 
    // defaultChecked = false,
    checked = _b.checked, 
    // vibrative = true,
    onChange = _b.onChange, others = __rest(_b, ["prefix", "className", "size", "disabled", "readOnly", "loading", "style", "checked", "onChange"]);
    var initChecked = getCheckedState(props);
    var _d = useState(initChecked), hasChecked = _d[0], setChecked = _d[1];
    var checkedPrefix = hasChecked ? '-checked' : '';
    var disabledPrefix = disabled ? '-disabled' : '';
    // class names of switch
    var switchClassNames = classNames(CN_UI_HASH_CLASS_NAME, "".concat(prefix, "switch"), "".concat(prefix, "switch--").concat(size), (_a = {},
        _a["".concat(prefix, "switch-").concat(disabledPrefix).concat(checkedPrefix)] = hasChecked || disabled,
        _a), className);
    // class names of knob
    var switchKnobClassNames = classNames("".concat(prefix, "switch-knob"), "".concat(prefix, "switch-knob--").concat(size).concat(checkedPrefix));
    // class names of loading
    var switchLoadingClassNames = classNames("".concat(prefix, "switch-knob-loading"), "".concat(prefix, "switch-knob-loading--").concat(size));
    // on switch tap
    var onSwitchTap = function (e) {
        if (disabled || readOnly) {
            return;
        }
        var checkedValue = !hasChecked;
        if (!('checked' in props)) {
            setChecked(checkedValue);
        }
        if (onChange) {
            onChange(checkedValue, e);
        }
    };
    useEffect(function () {
        if (initChecked !== hasChecked) {
            setChecked(initChecked);
        }
    }, [checked]);
    return (React.createElement("div", __assign({ className: switchClassNames, style: style, onClick: onSwitchTap }, others, { "data-testid": "cn-switch" }),
        React.createElement("div", { className: switchKnobClassNames }, loading ? (React.createElement(CnIcon, { className: switchLoadingClassNames, type: "icon-loading" })) : null)));
};
var CnSwitch = Switch;
CnSwitch.displayName = 'CnSwitch';
export { Switch as CnSwitch };
