import { numberFormat } from '@cainiaofe/cn-ui-common';
import isFunction from 'lodash/isFunction';
export function getFormattedValue(inputValue, format, innerAfter, label, adapterLocale) {
    var formatedValue = "".concat(inputValue);
    if (format && isFunction(format)) {
        formatedValue = format("".concat(inputValue));
    }
    else if (adapterLocale) {
        formatedValue = !formatedValue ? formatedValue :
            numberFormat(inputValue, adapterLocale);
    }
    var formatedValueWithInnerAfter = "".concat(formatedValue).concat(innerAfter);
    var vhasLabel = label + formatedValueWithInnerAfter;
    return vhasLabel;
}
