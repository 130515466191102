var _a;
import { __assign } from "tslib";
import React, { useCallback } from 'react';
import { useDrag } from '@use-gesture/react';
import { useSpring, animated } from '@react-spring/web';
import { bound } from '@/utils/bound';
import { ImageViewerSliderItem } from '../image-viewer-slider-item';
import { Footer } from '../footer';
import { useIndex } from './hooks/useIndex';
import './index.scss';
import $i18n from 'panda-i18n';
var isRTL = (_a = $i18n.isRTL) === null || _a === void 0 ? void 0 : _a.call($i18n);
var classPrefix = 'cn-ui-m-image-viewer-slider';
export var ImageViewerSlider = function (props) {
    var value = props.value, onTap = props.onTap, onError = props.onError;
    var _a = useIndex({
        index: props.index || 0,
        setIndex: props.setIndex,
    }), index = _a.index, setIndex = _a.setIndex;
    var count = value.length;
    var slideWidth = window.innerWidth + 16;
    var _b = useSpring(function () { return ({
        x: index * slideWidth,
        config: { tension: 250, clamp: true },
    }); }), x = _b[0].x, api = _b[1];
    var swipeTo = useCallback(function (_index, immediate) {
        if (immediate === void 0) { immediate = false; }
        setIndex(_index);
        var i = _index % count;
        api.start({
            x: i * slideWidth,
            immediate: immediate,
        });
    }, [api, count, setIndex, slideWidth]);
    var bind = useDrag(function (state) {
        var offsetX = state.offset[0];
        if (state.last) {
            var minIndex = Math.floor(offsetX / slideWidth);
            var maxIndex = minIndex + 1;
            var velocityOffset = Math.min(state.velocity[0] * 2000, slideWidth) * state.direction[0];
            swipeTo(bound(Math.round((offsetX + velocityOffset) / slideWidth), minIndex, maxIndex));
        }
        else {
            api.start({
                x: offsetX,
                immediate: true,
            });
        }
    }, {
        transform: function (_a) {
            var _x = _a[0], _y = _a[1];
            return [isRTL ? _x : -_x, _y];
        },
        from: function () { return [x.get(), 0]; },
        bounds: function () {
            return {
                left: 0,
                right: (count - 1) * slideWidth,
            };
        },
        rubberband: true,
        axis: 'x',
        pointer: { touch: true },
    });
    return (React.createElement("div", __assign({ className: classPrefix }, bind()),
        React.createElement(animated.div, { className: "".concat(classPrefix, "-content"), style: { x: x.to(function (_x) { return (isRTL ? _x : -_x); }) } }, value.map(function (item) { return (React.createElement(ImageViewerSliderItem, { key: item, value: item, onTap: onTap, onError: onError })); })),
        React.createElement(Footer, { index: index, value: value, swipeTo: swipeTo })));
};
