import { __assign, __rest } from "tslib";
import './array-card.scss';
import $i18n, { withI18n } from 'panda-i18n';
import locale from '@/locales';
import classNames from 'classnames';
import React from 'react';
import { observer, RecursionField, useField, useFieldSchema, } from '@formily/react';
import { CnFormArrayBase, mixinCnFormArrayBase, } from '@/form/cn-form-array-base';
import { CnIcon } from '@/components/cn-icon';
import { CnCard } from '@/form/cn-card';
import { CnBox } from '@/components/cn-box';
import { isOperationComponent } from '@/form/cn-form-array-base/utils';
import { Addition } from './addition';
import { getTitle } from '../utils/get-title';
import { useOperateColumn } from '../hooks/use-operate-column';
var CnFormArrayCardView = observer(function (props) {
    var title = props.title, autoIncrementTitle = props.autoIncrementTitle, additionText = props.additionText, hasDeleteButton = props.hasDeleteButton, defaultValue = props.defaultValue, _a = props.additionButtonVisible, additionButtonVisible = _a === void 0 ? true : _a, restProps = __rest(props, ["title", "autoIncrementTitle", "additionText", "hasDeleteButton", "defaultValue", "additionButtonVisible"]);
    var field = useField();
    var schema = useFieldSchema();
    var operateColumn = useOperateColumn();
    var dataSource = Array.isArray(field.value) ? field.value : [];
    var defaultCardTitle = $i18n.get({
        id: 'Cards',
        dm: '卡片',
        ns: 'CnFormArrayCard',
    });
    return (React.createElement(CnFormArrayBase, null,
        React.createElement(CnBox, { spacing: 12 },
            React.createElement("div", __assign({ "data-name": "CnFormArrayCard" }, restProps, { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-form-array-card-container', props.className) }),
                dataSource.map(function (item, index) {
                    var _a;
                    var rowProps = (_a = props.onRow) === null || _a === void 0 ? void 0 : _a.call(props, item, index);
                    var itemsSchema = Array.isArray(schema.items)
                        ? schema.items[index] || schema.items[0]
                        : schema.items;
                    var itemTitle = getTitle({
                        title: title,
                        item: item,
                        index: index,
                        defaultCardTitle: autoIncrementTitle || defaultCardTitle,
                    });
                    return (React.createElement(CnFormArrayBase.Item, { key: index, index: index, record: function () { return item; } },
                        React.createElement(CnCard, __assign({}, rowProps, { shape: "normal", title: itemTitle, action: React.createElement(React.Fragment, null,
                                hasDeleteButton && (React.createElement(CnIcon, { type: "icon-minus-r", outerClassName: "cn-ui-m-form-array-card__delete", onClick: function () { return field.remove(index); } })),
                                operateColumn ? (React.createElement(RecursionField, { schema: operateColumn, name: index, onlyRenderProperties: true })) : null) }),
                            React.createElement(RecursionField, { schema: itemsSchema, name: index, filterProperties: function (fieldSchema) {
                                    if (fieldSchema.name === 'indexColumn')
                                        return false;
                                    if (fieldSchema.name === 'operateColumn')
                                        return false;
                                    return !isOperationComponent(fieldSchema);
                                } }))));
                }),
                React.createElement(Addition, { additionButtonVisible: additionButtonVisible, schema: schema, additionText: additionText, defaultValue: defaultValue })))));
});
CnFormArrayCardView.displayName = 'CnFormArrayCard';
CnFormArrayCardView.defaultProps = {
    hasDeleteButton: true,
};
export var CnFormArrayCard = mixinCnFormArrayBase(withI18n(CnFormArrayCardView, { locale: locale }));
