import React, { useCallback, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { renderToContainer } from '@/utils/render-to-container';
import { ErrorImage, withNativeProps } from '@cainiaofe/cn-ui-common';
import { CnBox } from '../cn-box';
import { ImageViewerSlider } from './components/image-viewer-slider';
export function CnImageViewer(props) {
    var _src = props.src, width = props.width, height = props.height, single = props.single, onErrorProp = props.onError;
    var src = useMemo(function () {
        if (!_src)
            return [];
        if (typeof _src === 'string')
            return [_src];
        return _src;
    }, [_src]);
    var _a = useState(props.index || 0), index = _a[0], setIndex = _a[1];
    var _b = useState(false), visible = _b[0], setVisible = _b[1];
    var onClose = useCallback(function () {
        setVisible(false);
    }, []);
    var onError = function (e) {
        e.target.src = ErrorImage;
        onErrorProp === null || onErrorProp === void 0 ? void 0 : onErrorProp(e);
    };
    var node = (React.createElement(ImageViewerSlider, { setIndex: setIndex, index: index, value: src, onTap: onClose, onError: onError }));
    // 单图，默认宽100%，高自适应
    if (single) {
        return (React.createElement(React.Fragment, null,
            withNativeProps(props, React.createElement("div", { style: { width: width, height: height }, className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-image-single-viewer') },
                React.createElement("img", { src: src && src[0], style: { width: width, height: height }, onError: onError, onClick: function () {
                        setIndex(0);
                        setVisible(true);
                    } }))),
            visible ? renderToContainer(document.body, node) : null));
    }
    return (React.createElement(React.Fragment, null,
        withNativeProps(props, React.createElement(CnBox, { className: classNames('cn-ui-m-image-viewer'), wrap: true, direction: "row", spacing: 12 }, src.map(function (url, i) { return (React.createElement("div", { key: url, className: "cn-ui-m-image-viewer-item", style: { width: width, height: height }, onClick: function () {
                setIndex(i);
                setVisible(true);
            } },
            React.createElement("img", { src: url, style: { width: width, height: height }, onError: onError }))); }))),
        visible ? renderToContainer(document.body, node) : null));
}
CnImageViewer.displayName = 'CnImageViewer';
CnImageViewer.open = function (props) {
    var index = props.index, src = props.src, onErrorProp = props.onError;
    var getSrc = function () {
        if (!src)
            return [];
        if (typeof src === 'string')
            return [src];
        return src;
    };
    var container = document.createElement('div');
    document.body.appendChild(container);
    var hide = function () { return container.remove(); };
    var onError = function (e) {
        e.target.src = ErrorImage;
        onErrorProp === null || onErrorProp === void 0 ? void 0 : onErrorProp(e);
    };
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(React.createElement(ImageViewerSlider, { index: index, value: getSrc(), onTap: hide, onError: onError }), container);
    return { hide: hide };
};
