import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { CardItemsStateCtx } from '../../context/card-items';
import { CnIcon } from '@/components/cn-icon';
import { CnDialogFullpage } from '@/components/cn-dialog-fullpage';
import { CnPageFooter } from '@/components/cn-page-footer';
import { List } from '../list';
import { Toolbar } from '../toolbar';
import './index.scss';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
/** 子卡片列表 */
export var ListSubCard = function (props) {
    var state = useContext(CardItemsStateCtx);
    var _a = useState(false), visible = _a[0], setVisible = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { "data-testid": "cn-card-items-sub-card", className: "cn-ui-m-card-items-sub-list", onClick: function () { return setVisible(true); } },
            React.createElement("div", null, state.entryTitle),
            React.createElement(CnIcon, { type: "arrow-right" })),
        React.createElement(CnDialogFullpage, { noCard: true, title: $i18n.get({
                id: '31255239428542464.CNTM',
                dm: '详情',
                ns: 'CnCardItems',
            }), visible: visible, onClose: function () { return setVisible(false); }, footer: state.showToolbar ? (React.createElement(CnPageFooter, null,
                React.createElement(Toolbar, __assign({}, state)))) : (false) }, withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-card-items-container'), "data-testid": "cn-card-items-container" },
            React.createElement(List, null))))));
};
