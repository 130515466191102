import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import { CnDatePickerPro } from './cn-date-picker-pro';
var CnMonthPickerPro = function (props) {
    return (React.createElement(CnDatePickerPro, __assign({ precision: "month", placeholder: $i18n.get({
            id: 'PleaseSelectTheMonth',
            dm: '请选择月',
            ns: 'CnDatePickerPro',
        }) }, props)));
};
CnMonthPickerPro.displayName = 'CnMonthPickerPro';
export { CnMonthPickerPro };
