import { __assign } from "tslib";
import React from 'react';
import { CnCard } from './cn-card';
import CnBaseCard from './components/cn-base-card';
var CnCardView = function (props) {
    // 没有设置shape维持老卡片
    if (!(props === null || props === void 0 ? void 0 : props.shape)) {
        return React.createElement(CnCard, __assign({}, props));
    }
    // 走新卡片组件
    var newProps = __assign({}, props);
    var hasTitle = props.title || props.desc || props.icon || props.action;
    var hasSubTitle = props.subTitle || props.subDesc || props.subIcon || props.subAction;
    // 兼容老版本只设置了内标题
    if (!hasTitle && hasSubTitle) {
        newProps.title = props.subTitle;
        newProps.desc = props.subDesc;
        newProps.icon = props.subIcon;
        newProps.action = props.subAction;
        return React.createElement(CnBaseCard, __assign({}, newProps));
    }
    // 兼容老版本设置了内外标题
    if (hasTitle && hasSubTitle) {
        return (React.createElement(CnBaseCard, { title: props.title, desc: props.desc, icon: props.icon, action: props.action, shape: "group" },
            React.createElement(CnBaseCard, __assign({}, newProps, { title: props.subTitle, desc: props.subDesc, icon: props.subIcon, action: props.subAction }))));
    }
    return React.createElement(CnBaseCard, __assign({}, newProps));
};
export default CnCardView;
