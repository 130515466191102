import { __assign, __spreadArray } from "tslib";
import React from 'react';
import { useI18n } from 'panda-i18n';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';
import { useControlled } from '@cainiaofe/cn-ui-common';
import { CnStatusTag } from '@/components/cn-tag';
import { CnCheckbox } from '@/components/cn-checkbox';
import { CnButton } from '@/components/cn-button';
import { CnButtonGroup } from '@/components/cn-button-group';
import { CnIcon } from '@/components/cn-icon';
import { CnPopover } from '@/components/cn-popover';
import './index.scss';
var renderElement = function (basis, element) {
    return basis ? element : null;
};
var renderTags = function (tags) {
    return Array.isArray(tags)
        ? tags.map(function (item, index) {
            return React.createElement(CnStatusTag, __assign({}, item, { key: index }));
        })
        : null;
};
var CnCardFooterActions = function (props) {
    var checkBox = props.checkBox, _a = props.buttons, buttons = _a === void 0 ? [] : _a;
    var $i18n = useI18n();
    if (!checkBox && !buttons.length)
        return null;
    return (React.createElement(React.Fragment, null,
        checkBox ? (React.createElement("div", { className: "cn-ui-m-base-card-footer-checkbox-group" },
            React.createElement(CnCheckbox, __assign({ checked: checkBox.checked }, checkBox), $i18n.get({ id: 'Selection', dm: '选择', ns: 'CnCard' })))) : null,
        buttons.length ? (React.createElement(CnButtonGroup, { className: "cn-ui-m-base-card-footer-button-group", shape: "card" }, __spreadArray([], buttons, true).reverse().map(function (btnProps, index) {
            if (typeof btnProps === 'object' && btnProps.children) {
                return React.createElement(CnButton, __assign({}, btnProps, { key: index }));
            }
            return btnProps;
        }))) : null));
};
var CnBaseCard = function (props) {
    var _a, _b, _c;
    var className = props.className, style = props.style, shape = props.shape, icon = props.icon, title = props.title, titleTag = props.titleTag, titleTips = props.titleTips, noTitleBorder = props.noTitleBorder, tags = props.tags, desc = props.desc, foldable = props.foldable, action = props.action, children = props.children, footAction = props.footAction, contentGap = props.contentGap, onClick = props.onClick, onClickCapture = props.onClickCapture;
    var noPadding = shape === 'group' ? true : props === null || props === void 0 ? void 0 : props.noPadding;
    // 是否折叠
    var $i18n = useI18n();
    var _d = useControlled(props, {
        valuePropName: 'fold',
        defaultValuePropName: 'initFold',
        onChangePropName: 'onFoldChange',
    }), fold = _d[0], setFold = _d[1];
    var iconWarpClassName = classNames((_a = {
            'cn-ui-m-base-card-header-title-icon-wrap': true
        },
        _a["cn-ui-m-base-card-header-title-icon-wrap-".concat(shape)] = !!shape,
        _a));
    var descClassName = classNames({
        'cn-ui-m-base-card-desc': true,
        'cn-ui-m-base-card-desc-fold': fold,
    });
    var footerClassName = classNames({
        'cn-ui-m-base-card-footer': true,
        'cn-ui-m-base-card-footer-fold': fold,
    });
    // 兼容老的卡片做转换
    var iconProps = React.useMemo(function () {
        if (icon) {
            if (typeof icon === 'string') {
                return {
                    type: icon,
                };
            }
            return icon;
        }
        return null;
    }, [icon]);
    // 兼容老的api和一码多端
    var titleRightTagProps = React.useMemo(function () {
        var tag = titleTag || tags;
        if (Array.isArray(tag) && tag.length <= 2) {
            return tag;
        }
        if (isObject(tag)) {
            return [tag];
        }
        return null;
    }, [titleTag, tags]);
    var titleBottomTagProps = React.useMemo(function () {
        var tag = titleTag || tags;
        if (Array.isArray(tag) && tag.length > 2) {
            return tag;
        }
        return null;
    }, [titleTag, tags]);
    var headerClassName = classNames((_b = {
            'cn-ui-m-base-card-header': true
        },
        _b["cn-ui-m-base-card-header-".concat(shape)] = !!shape,
        _b['cn-ui-m-base-card-header-no-border'] = noTitleBorder,
        _b['cn-ui-m-base-card-header-no-padding'] = noPadding,
        _b['cn-ui-m-base-card-header-has-bottom-tags'] = titleBottomTagProps,
        _b));
    var renderHeader = iconProps ||
        title ||
        titleTips ||
        (titleRightTagProps === null || titleRightTagProps === void 0 ? void 0 : titleRightTagProps.length) ||
        action ||
        foldable ||
        titleBottomTagProps;
    var cardClassName = classNames(CN_UI_HASH_CLASS_NAME, (_c = {
            'cn-ui-m-base-card': true
        },
        _c[className] = !!className,
        _c["cn-ui-m-base-card-".concat(shape)] = !!shape,
        _c['cn-ui-m-base-card-no-padding'] = noPadding,
        _c['cn-ui-m-base-card-no-title'] = !renderHeader && !desc && noPadding,
        _c));
    var contentClassName = classNames({
        'cn-ui-m-base-card-content': true,
        'cn-ui-m-base-card-content-fold': fold,
        'cn-ui-m-base-card-content-no-padding': noPadding,
        'cn-ui-m-base-card-content-no-title': !renderHeader && !desc && noPadding,
        'cn-ui-m-base-card-content-gap': contentGap,
    });
    return (React.createElement("div", { onClick: onClick, onClickCapture: onClickCapture, className: cardClassName, style: style },
        renderHeader ? (React.createElement("div", { className: headerClassName },
            React.createElement("div", { className: "cn-ui-m-base-card-header-content" },
                React.createElement("div", { className: "cn-ui-m-base-card-header-title-container" },
                    renderElement(iconProps || shape === 'primary', React.createElement("div", { className: iconWarpClassName },
                        React.createElement(CnIcon, __assign({ className: "cn-ui-m-base-card-header-title-icon", size: shape === 'primary' || shape === 'group' ? 'xs' : 'medium', type: "service-center" }, iconProps)))),
                    renderElement(title, React.createElement("div", { className: "cn-ui-m-base-card-header-title-text" }, title)),
                    renderElement(titleTips, React.createElement("div", { className: "cn-ui-m-base-card-header-title-tip" },
                        React.createElement("div", { className: "cn-ui-m-base-card-header-title-tip-icon" },
                            React.createElement(CnPopover, { mode: "dark", content: titleTips, trigger: "click" },
                                React.createElement(CnIcon, { type: "icon-help" }))))),
                    renderElement(titleRightTagProps, React.createElement("div", { className: "cn-ui-m-base-card-header-title-tag" }, renderTags(titleRightTagProps)))),
                React.createElement("div", { className: "cn-ui-m-base-card-header-action" },
                    action,
                    renderElement(foldable, React.createElement("div", { className: "cn-ui-m-base-card-header-action-fold", onClick: function () { return setFold(!fold); } },
                        fold
                            ? $i18n.get({ id: 'TERM.Expand', dm: '展开', ns: 'CnCard' })
                            : $i18n.get({
                                id: 'TERM.Collapse',
                                dm: '收起',
                                ns: 'CnCard',
                            }),
                        React.createElement(CnIcon, { size: "xs", outerClassName: "cn-ui-m-base-card-header-action-fold-icon", type: !fold ? 'triangle-up-fill' : 'triangle-down-fill' }))))),
            renderElement(titleBottomTagProps, React.createElement("div", { className: "cn-ui-m-base-card-header-tags" }, renderTags(titleBottomTagProps))))) : null,
        renderElement(desc, React.createElement("div", { className: descClassName }, desc)),
        renderElement(children, React.createElement("div", { className: contentClassName }, children)),
        renderElement(footAction, React.createElement("div", { className: footerClassName },
            React.createElement(CnCardFooterActions, __assign({}, footAction))))));
};
CnBaseCard.propTypes = {
    contentGap: PropTypes.bool,
};
CnBaseCard.defaultProps = {
    contentGap: false,
};
export default CnBaseCard;
