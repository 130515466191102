import { __assign, __rest } from "tslib";
import React from 'react';
import Table from 'rc-table';
import './index.scss';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
import classNames from 'classnames';
/**
 * @category 组件
 *  mini表格
 */
export var CnMiniTable = function (props) {
    var _a;
    var prefixCls = 'cn-ui-m-mini-table';
    var hasBorder = props.hasBorder, rest = __rest(props, ["hasBorder"]);
    var cls = classNames((_a = {},
        _a["".concat(prefixCls, "-has-border")] = hasBorder,
        _a));
    return withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, "".concat(prefixCls, "-wrap")) },
        React.createElement(Table, __assign({ className: cls, prefixCls: prefixCls }, rest))));
};
CnMiniTable.defaultProps = {
    hasBorder: true,
};
CnMiniTable.displayName = 'CnMiniTable';
