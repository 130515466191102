import './index.scss';
import { useI18n } from 'panda-i18n';
import React, { useImperativeHandle, useRef } from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { CnUploader, withNativeProps, useUploadState, } from '@cainiaofe/cn-ui-common';
import { UploadList } from '../upload-list';
import { UploadConfirm } from './upload-confirm';
export var UploadView = React.forwardRef(function (props, ref) {
    var $i18n = useI18n();
    var uploadConfirm = props.uploadConfirm, placeholder = props.placeholder;
    var _a = useUploadState(), getValue = _a.getValue, readOnly = _a.readOnly, limited = _a.limited;
    var uploadRef = useRef(null);
    useImperativeHandle(ref, function () { return uploadRef.current; });
    if (readOnly) {
        if (!getValue().length) {
            return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-oss-upload', 'cn-oss-upload-readonly') }, "- -"));
        }
        return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-oss-upload', 'cn-oss-upload-readonly') },
            React.createElement(UploadList, null)));
    }
    return withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-oss-upload', {
            'cn-oss-upload-limited': limited,
        }), "data-testid": "cn-oss-upload" },
        React.createElement("div", { className: "cn-ui-m-oss-upload-add" },
            React.createElement(UploadConfirm, { uploadConfirm: uploadConfirm, openFileDialog: function () { var _a, _b; return (_b = (_a = uploadRef.current) === null || _a === void 0 ? void 0 : _a.uploader) === null || _b === void 0 ? void 0 : _b.onClick(); } }),
            React.createElement(CnUploader, { ref: uploadRef, className: "cn-ui-m-oss-upload-action" }, limited ? (React.createElement("span", { className: "cn-ui-m-oss-upload-text" }, $i18n.get({
                id: 'MaximumQuantityReached',
                dm: '已达数量上限',
                ns: 'CnOssUpload',
            }))) : (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "cn-ui-m-oss-upload-text" }, placeholder ||
                    $i18n.get({
                        id: 'PleaseUpload',
                        dm: '请上传',
                        ns: 'CnOssUpload',
                    })),
                React.createElement(CnIcon, { type: "upload", size: "small" }))))),
        React.createElement(UploadList, null)));
});
UploadView.displayName = 'UploadView';
