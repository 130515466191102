import { dayjs } from '@/utils/dayjs';
import isFunction from 'lodash/isFunction';
/**
 * 获取日期格式化后的string[]
 * @param vals 值
 * @param format 格式
 * @returns
 */
export function getRangeStr(vals, format) {
    var valStr = vals.map(function (val) {
        if (!val) {
            return '';
        }
        if (isFunction(format)) {
            return format(dayjs(val));
        }
        return dayjs(val).format(format || 'YYYY-MM-DD HH:mm:ss');
    });
    return valStr;
}
