var _a;
import { __assign, __awaiter, __generator } from "tslib";
import { request } from 'cn-request';
/**
 * 请求类型
 */
var REQUEST_TYPE;
(function (REQUEST_TYPE) {
    /** 获取部门 */
    REQUEST_TYPE["GET_DEPT"] = "GET_GEPT";
    /**
     * 获取子节点
     */
    REQUEST_TYPE["GET_CHILDREN"] = "GET_CHILDREN";
    /**
     * 搜索
     */
    REQUEST_TYPE["SEARCH"] = "SEARCH";
})(REQUEST_TYPE || (REQUEST_TYPE = {}));
/**
 * 内置接口名称
 */
var INTERNAL_PATH_MAP = (_a = {},
    /**
     * 获取部门
     */
    _a[REQUEST_TYPE.GET_DEPT] = '/cmdp/hysmab/getDepartmentInfo',
    /**
     * 获取子部门
     */
    _a[REQUEST_TYPE.GET_CHILDREN] = '/cmdp/hysmab/getSubDepartmentInfo',
    /**
     * 搜索
     */
    _a[REQUEST_TYPE.SEARCH] = '/cmdp/hysmab/searchDepartmentInfo',
    _a);
/**
 * 非mokelay直接接入时的代理地址
 */
var NON_MOKELY_PROXY_PATH = '/cn-common-proxy/post';
/**
 * 拼接请求参数
 */
function buildRequestParams(params) {
    var data = params.data, type = params.type, _a = params.mokelay, mokelay = _a === void 0 ? false : _a, _b = params.host, host = _b === void 0 ? '' : _b, _c = params.path, path = _c === void 0 ? '' : _c;
    // 拼接地址，优先外部传入的path，其次host+mokelay判断
    var innerPath = INTERNAL_PATH_MAP[type];
    var builtPath = path || (mokelay ? innerPath : NON_MOKELY_PROXY_PATH);
    // 拼接参数
    var builtData = path || mokelay
        ? data
        : {
            path: innerPath,
            params: data,
        };
    // 最终请求参数
    var builtParams = {
        url: "".concat(host).concat(builtPath),
        method: 'post',
        withCredentials: true,
        data: builtData,
    };
    return builtParams;
}
/**
 * 格式化返回数据
 * @param res 原始返回数据
 * @returns 格式化后可被下拉组件使用的数据
 */
function formatResponse(res) {
    var _a, _b;
    var resultData = Array.isArray((_a = res.data) === null || _a === void 0 ? void 0 : _a.data) ? (_b = res.data) === null || _b === void 0 ? void 0 : _b.data : res === null || res === void 0 ? void 0 : res.data;
    if (!Array.isArray(resultData))
        return [];
    return resultData
        .filter(function (item) { return (item === null || item === void 0 ? void 0 : item.enabled) !== false; })
        .map(function (item) { return (__assign(__assign({}, item), { label: item.name, fullName: "".concat(item.fullName || item.name).replace(/(^菜鸟集团-菜鸟-菜鸟-)|(菜鸟集团-菜鸟-菜鸟-$)/g, ''), value: item.code, isLeaf: !item.hasSubDepartment })); });
}
/**
 * 获取部门
 */
export function getDept(params) {
    return __awaiter(this, void 0, void 0, function () {
        var mokelay, host, path, _a, value, requestParams, result, fomattedResult;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    mokelay = params.mokelay, host = params.host, path = params.path, _a = params.value, value = _a === void 0 ? '' : _a;
                    requestParams = buildRequestParams({
                        type: REQUEST_TYPE.GET_DEPT,
                        data: {
                            departmentCode: value,
                            isCommonRequest: true,
                        },
                        mokelay: mokelay,
                        host: host,
                        path: path,
                    });
                    return [4 /*yield*/, request(requestParams)];
                case 1:
                    result = _b.sent();
                    fomattedResult = formatResponse(result);
                    return [2 /*return*/, fomattedResult];
            }
        });
    });
}
/**
 * 获取子节点（父级部门获取子部门）
 */
export function getChildren(params) {
    return __awaiter(this, void 0, void 0, function () {
        var mokelay, host, path, _a, value, maxLevel, requestParams, result, fomattedResult;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    mokelay = params.mokelay, host = params.host, path = params.path, _a = params.value, value = _a === void 0 ? '' : _a, maxLevel = params.maxLevel;
                    requestParams = buildRequestParams({
                        type: REQUEST_TYPE.GET_CHILDREN,
                        data: {
                            departmentCode: value,
                            isCommonRequest: true,
                            maxLevel: maxLevel,
                        },
                        mokelay: mokelay,
                        host: host,
                        path: path,
                    });
                    return [4 /*yield*/, request(requestParams)];
                case 1:
                    result = _b.sent();
                    fomattedResult = formatResponse(result);
                    return [2 /*return*/, fomattedResult];
            }
        });
    });
}
/**
 * 搜索（按关键词查询部门）
 */
export function search(params) {
    return __awaiter(this, void 0, void 0, function () {
        var mokelay, host, path, _a, value, maxLevel, requestParams, result, fomattedResult;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    mokelay = params.mokelay, host = params.host, path = params.path, _a = params.value, value = _a === void 0 ? '' : _a, maxLevel = params.maxLevel;
                    requestParams = buildRequestParams({
                        type: REQUEST_TYPE.SEARCH,
                        data: {
                            searchKey: value,
                            isCommonRequest: true,
                            maxLevel: maxLevel,
                        },
                        mokelay: mokelay,
                        host: host,
                        path: path,
                    });
                    return [4 /*yield*/, request(requestParams)];
                case 1:
                    result = _b.sent();
                    fomattedResult = formatResponse(result);
                    return [2 /*return*/, fomattedResult.map(function (i) { return (__assign(__assign({}, i), { label: i.fullName || i.label })); })];
            }
        });
    });
}
