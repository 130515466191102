import { dateToDayjs } from '@/components/cn-time-picker-pro/utils';
import { isValidDate } from '@/utils/date';
export function getDefaultTime(value, defaultValue, format) {
    if (isValidDate(value)) {
        return value;
    }
    if (defaultValue) {
        return dateToDayjs(defaultValue, format);
    }
    return new Date();
}
