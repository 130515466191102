import { __assign, __rest } from "tslib";
import React, { useMemo } from 'react';
import isNumber from 'lodash/isNumber';
import { judgeResponsiveSize } from '@/utils/responsive';
/**
 * @category 组件
 *  栅格布局
 */
var CnGridLayout = function (props) {
    var grids = props.grids, gutter = props.gutter, responsive = props.responsive, children = props.children, rest = __rest(props, ["grids", "gutter", "responsive", "children"]);
    var calculatedGutter = useMemo(function () {
        var rowGutter = 8;
        var colGutter = 12;
        if (Array.isArray(gutter) && gutter.length > 0) {
            if (gutter.length === 1) {
                rowGutter = gutter[0];
                colGutter = gutter[0];
            }
            else {
                rowGutter = gutter[0];
                colGutter = gutter[1];
            }
        }
        return { rowGutter: rowGutter, colGutter: colGutter };
    }, [gutter]);
    var calculatedColumns = useMemo(function () {
        var columns = 4;
        if (responsive) {
            if (grids && grids instanceof Object) {
                var gridsObj = Object(grids);
                var responsiveSize = judgeResponsiveSize();
                if (gridsObj[responsiveSize]) {
                    columns = gridsObj[responsiveSize];
                }
            }
        }
        else if (isNumber(grids)) {
            columns = grids;
        }
        return columns;
    }, [responsive, grids]);
    var styleStyledGridLayout = {
        width: '100%',
        display: 'grid',
        gridRowGap: "".concat(calculatedGutter.rowGutter, "px"),
        gridColumnGap: "".concat(calculatedGutter.colGutter, "px"),
        gridTemplateColumns: "repeat(".concat(calculatedColumns, ", minmax(0, 1fr))"),
    };
    return (React.createElement("div", __assign({ style: styleStyledGridLayout }, rest), children));
};
/**
 * @category 组件
 *  栅格布局子组件，上层组件为 CnGridLayout
 */
var CnGridItem = function (props) {
    var colSpan = props.colSpan, children = props.children, style = props.style, rest = __rest(props, ["colSpan", "children", "style"]);
    var calculatedColSpan = useMemo(function () {
        var spans = 1;
        if (colSpan instanceof Object) {
            var spanObj = Object(colSpan);
            var responsiveSize = judgeResponsiveSize();
            if (spanObj[responsiveSize]) {
                spans = spanObj[responsiveSize];
            }
        }
        else {
            spans = Number(colSpan || 1);
        }
        return spans;
    }, [colSpan]);
    var styledGridLayoutItem = __assign({ gridColumnEnd: "span ".concat(calculatedColSpan), gridRowEnd: "span ".concat(rest.rowSpan) }, style);
    return (React.createElement("div", __assign({}, rest, { style: styledGridLayoutItem }), children));
};
var styledGridLayoutItemDemo = {
    display: 'flex',
    background: '#c0ccdd',
    color: 'white',
    justifyContent: 'center',
};
var CnGridItemDemo = function (props) {
    return React.createElement("span", { style: styledGridLayoutItemDemo }, props.children);
};
/**
 * @deprecated 不建议使用
 */
var CnGrid = {
    CnRow: CnGridLayout,
    CnCol: CnGridItem,
};
export { CnGrid, CnGridLayout, CnGridItem, CnGridItemDemo };
