import * as cnUtils from '@cainiaofe/cn-utils';
import { dayjs } from './dayjs';
import { isNil } from '@/utils/func';
export function isInvalidValue(val) {
    return isNil(val) || val === '';
}
function isDate(obj) {
    return typeof obj === 'object' && obj instanceof Date;
}
export function formatDate(d, format, invalidStr) {
    if (invalidStr === void 0) { invalidStr = ''; }
    if (!isInvalidValue(d)) {
        var date = dayjs(d);
        if (date.isValid()) {
            return date.format(format);
        }
    }
    return invalidStr;
}
export function isValidDate(obj) {
    return isDate(obj) && !isNaN(obj.getTime());
}
export function toValidDate(from, defaultDate) {
    var res = toDate(from);
    return isValidDate(res) ? res : defaultDate || new Date();
}
export function toDate(from) {
    if (isInvalidValue(from)) {
        return undefined;
    }
    if (isDate(from)) {
        return from;
    }
    // 兼容 moment 或类似 API 一致的工具库
    if (typeof from === 'object' && typeof from.toDate === 'function') {
        var d = from.toDate();
        if (isDate(d)) {
            return d;
        }
    }
    return toDay(from).toDate();
}
/**
 * 日期是否在指定区间
 * @param date
 * @param startDate
 * @param endDate
 * @param unit
 * @param intervalMode
 */
export function isBetween(date, startDate, endDate, unit, intervalMode) {
    if (unit === void 0) { unit = 'd'; }
    if (intervalMode === void 0) { intervalMode = '[]'; }
    return dayjs(date).isBetween(startDate, endDate, unit, intervalMode);
}
export function toDay(from) {
    return dayjs(from);
}
export function cloneDate(d) {
    return new Date(d.getTime());
}
export function getDateNum(type, d) {
    switch (type) {
        case 'y':
            return d.getFullYear();
        case 'm':
            return d.getMonth();
        case 'd':
            return d.getDate();
        case 'h':
            return d.getHours();
        case 'i':
            return d.getMinutes();
        case 's':
            return d.getSeconds();
    }
}
export function isTypeSame(type, d1, d2) {
    return getDateNum(type, d1) === getDateNum(type, d2);
}
export var RANGE_CHECK_RESULT;
(function (RANGE_CHECK_RESULT) {
    RANGE_CHECK_RESULT[RANGE_CHECK_RESULT["IN_RANGE"] = 0] = "IN_RANGE";
    RANGE_CHECK_RESULT[RANGE_CHECK_RESULT["SMALL_THAN_HOUR"] = 1] = "SMALL_THAN_HOUR";
    RANGE_CHECK_RESULT[RANGE_CHECK_RESULT["BIGGER_THAN_HOUR"] = 2] = "BIGGER_THAN_HOUR";
    RANGE_CHECK_RESULT[RANGE_CHECK_RESULT["SMALL_THAN_MIN"] = 3] = "SMALL_THAN_MIN";
    RANGE_CHECK_RESULT[RANGE_CHECK_RESULT["BIGGER_THAN_MIN"] = 4] = "BIGGER_THAN_MIN";
    RANGE_CHECK_RESULT[RANGE_CHECK_RESULT["SMALL_THAN_SEC"] = 5] = "SMALL_THAN_SEC";
    RANGE_CHECK_RESULT[RANGE_CHECK_RESULT["BIGGER_THAN_SEC"] = 6] = "BIGGER_THAN_SEC";
    RANGE_CHECK_RESULT[RANGE_CHECK_RESULT["SMALL_THAN_YEAR"] = 7] = "SMALL_THAN_YEAR";
    RANGE_CHECK_RESULT[RANGE_CHECK_RESULT["BIGGER_THAN_YEAR"] = 8] = "BIGGER_THAN_YEAR";
    RANGE_CHECK_RESULT[RANGE_CHECK_RESULT["SMALL_THAN_MONTH"] = 9] = "SMALL_THAN_MONTH";
    RANGE_CHECK_RESULT[RANGE_CHECK_RESULT["BIGGER_THAN_MONTH"] = 10] = "BIGGER_THAN_MONTH";
    RANGE_CHECK_RESULT[RANGE_CHECK_RESULT["SMALL_THAN_DAY"] = 11] = "SMALL_THAN_DAY";
    RANGE_CHECK_RESULT[RANGE_CHECK_RESULT["BIGGER_THAN_DAY"] = 12] = "BIGGER_THAN_DAY";
})(RANGE_CHECK_RESULT || (RANGE_CHECK_RESULT = {}));
var TIME_PRE_CHECK;
(function (TIME_PRE_CHECK) {
    TIME_PRE_CHECK[TIME_PRE_CHECK["NONE"] = 0] = "NONE";
    TIME_PRE_CHECK[TIME_PRE_CHECK["SKIP"] = 1] = "SKIP";
    TIME_PRE_CHECK[TIME_PRE_CHECK["USE_START"] = 2] = "USE_START";
    TIME_PRE_CHECK[TIME_PRE_CHECK["USE_END"] = 3] = "USE_END";
})(TIME_PRE_CHECK || (TIME_PRE_CHECK = {}));
/**
 * 检查某个日期 d 是否在 validRange 范围中
 * 这里的 bigger/smaller，都指的是范围的最大（小）值比日期 d 大/小
 * @param d 待检查的日期
 * @param options 选项，具体检查哪些内容
 * @param validRange 预期范围
 */
export function checkDateRange(d, options, validRange) {
    if (!validRange) {
        return RANGE_CHECK_RESULT.IN_RANGE;
    }
    var startDate = validRange[0], endDate = validRange[1];
    var isCheckDate = TIME_PRE_CHECK.NONE;
    // 检查日期
    if (options.date) {
        var _a = options.date, hasYear = _a.hasYear, hasMonth = _a.hasMonth, hasDay = _a.hasDay;
        isCheckDate = TIME_PRE_CHECK.SKIP;
        if (hasYear) {
            if (getDateNum('y', startDate) > getDateNum('y', d)) {
                return RANGE_CHECK_RESULT.BIGGER_THAN_YEAR;
            }
            if (getDateNum('y', endDate) < getDateNum('d', d)) {
                return RANGE_CHECK_RESULT.SMALL_THAN_YEAR;
            }
        }
        // 只有在 年 是边界值时，才检查 月
        var isStartYear = isTypeSame('y', d, startDate);
        var isEndYear = isTypeSame('y', d, endDate);
        if ((isStartYear || isEndYear) && hasMonth) {
            if (isStartYear && getDateNum('m', startDate) > getDateNum('m', d)) {
                return RANGE_CHECK_RESULT.BIGGER_THAN_MONTH;
            }
            if (isEndYear && getDateNum('m', endDate) < getDateNum('m', d)) {
                return RANGE_CHECK_RESULT.SMALL_THAN_MONTH;
            }
        }
        // 同上
        var isStartMonth = isStartYear && isTypeSame('m', d, startDate);
        var isEndMonth = isEndYear && isTypeSame('m', d, endDate);
        if (isStartMonth || isEndMonth) {
            if (hasDay) {
                if (isStartMonth && getDateNum('d', startDate) > getDateNum('d', d)) {
                    return RANGE_CHECK_RESULT.BIGGER_THAN_DAY;
                }
                if (isEndMonth && getDateNum('d', endDate) < getDateNum('d', d)) {
                    return RANGE_CHECK_RESULT.SMALL_THAN_DAY;
                }
                // 年、月、日都匹配，看一下是开始的还是结束的
                if (isEndMonth && isTypeSame('d', endDate, d)) {
                    isCheckDate = TIME_PRE_CHECK.USE_END;
                }
                if (isStartMonth && isTypeSame('d', startDate, d)) {
                    isCheckDate = TIME_PRE_CHECK.USE_START;
                }
            }
            else {
                // 不检查日期的时候，就用月份来计算
                isCheckDate = isStartMonth
                    ? TIME_PRE_CHECK.USE_START
                    : TIME_PRE_CHECK.USE_END;
            }
        }
    }
    // 检查时间
    if (options.time && isCheckDate !== TIME_PRE_CHECK.SKIP) {
        var _b = options.time, hasHour = _b.hasHour, hasMinute = _b.hasMinute, hasSecond = _b.hasSecond;
        if (hasHour) {
            if (isCheckDate !== TIME_PRE_CHECK.USE_END &&
                getDateNum('h', startDate) > getDateNum('h', d)) {
                return RANGE_CHECK_RESULT.BIGGER_THAN_HOUR;
            }
            if (isCheckDate !== TIME_PRE_CHECK.USE_START &&
                getDateNum('h', endDate) < getDateNum('h', d)) {
                return RANGE_CHECK_RESULT.SMALL_THAN_HOUR;
            }
        }
        // 只有在“小时”是边界值时，才检查分钟
        var isStartHour = isTypeSame('h', d, startDate);
        var isEndHour = isTypeSame('h', d, endDate);
        if ((isStartHour || isEndHour) && hasMinute) {
            if (isStartHour &&
                isCheckDate !== TIME_PRE_CHECK.USE_END &&
                getDateNum('i', startDate) > getDateNum('i', d)) {
                return RANGE_CHECK_RESULT.BIGGER_THAN_MIN;
            }
            if (isEndHour &&
                isCheckDate !== TIME_PRE_CHECK.USE_START &&
                getDateNum('i', endDate) < getDateNum('i', d)) {
                return RANGE_CHECK_RESULT.SMALL_THAN_MIN;
            }
        }
        // 同上，检查秒
        var isStartMinutes = isStartHour && isTypeSame('i', d, startDate);
        var isEndMinutes = isEndHour && isTypeSame('i', d, endDate);
        if ((isStartMinutes || isEndMinutes) && hasSecond) {
            if (isStartMinutes &&
                isCheckDate !== TIME_PRE_CHECK.USE_END &&
                getDateNum('s', startDate) > getDateNum('s', d)) {
                return RANGE_CHECK_RESULT.BIGGER_THAN_SEC;
            }
            if (isEndMinutes &&
                isCheckDate !== TIME_PRE_CHECK.USE_START &&
                getDateNum('s', endDate) < getDateNum('s', d)) {
                return RANGE_CHECK_RESULT.SMALL_THAN_SEC;
            }
        }
    }
    return RANGE_CHECK_RESULT.IN_RANGE;
}
/**
 * 获取在范围内最接近 d 的日期
 * @param d 原始日期
 * @param options 检查选项
 * @param validRange 范围
 */
export function getRangeDate(d, options, validRange) {
    if (!validRange) {
        return d;
    }
    var startDate = validRange[0], endDate = validRange[1];
    var newDate;
    switch (checkDateRange(d, options, validRange)) {
        case RANGE_CHECK_RESULT.IN_RANGE:
            return d;
        case RANGE_CHECK_RESULT.SMALL_THAN_YEAR:
        case RANGE_CHECK_RESULT.SMALL_THAN_MONTH:
        case RANGE_CHECK_RESULT.SMALL_THAN_DAY:
        case RANGE_CHECK_RESULT.SMALL_THAN_HOUR:
        case RANGE_CHECK_RESULT.SMALL_THAN_MIN:
        case RANGE_CHECK_RESULT.SMALL_THAN_SEC:
            newDate = cloneDate(endDate);
            break;
        case RANGE_CHECK_RESULT.BIGGER_THAN_YEAR:
        case RANGE_CHECK_RESULT.BIGGER_THAN_MONTH:
        case RANGE_CHECK_RESULT.BIGGER_THAN_DAY:
        case RANGE_CHECK_RESULT.BIGGER_THAN_HOUR:
        case RANGE_CHECK_RESULT.BIGGER_THAN_MIN:
        case RANGE_CHECK_RESULT.BIGGER_THAN_SEC:
            newDate = cloneDate(startDate);
            break;
    }
    if (options.date) {
        var _a = options.date, hasMonth = _a.hasMonth, hasDay = _a.hasDay;
        // 根据选项，重置相应部分
        if (!hasDay) {
            newDate.setDate(1);
        }
        if (!hasMonth) {
            newDate.setMonth(0);
            // 避免跳月BUG，这里再设置一次
            if (newDate.getMonth() !== 0) {
                newDate.setMonth(0);
            }
        }
    }
    if (options.time) {
        // 重置不存在的部分
        var _b = options.time, hasHour = _b.hasHour, hasMinute = _b.hasMinute, hasSecond = _b.hasSecond;
        if (!hasHour) {
            newDate.setHours(0);
        }
        if (!hasMinute) {
            newDate.setMinutes(0);
        }
        if (!hasSecond) {
            newDate.setSeconds(0);
        }
    }
    else {
        // 没有时间选项，就全部重置掉
        newDate.setHours(0, 0, 0);
    }
    newDate.setMilliseconds(0);
    return newDate;
}
/**
 * 根据禁用选项，获取最接近的日期
 * @param options 时间选项
 * @param extra 时间选项
 * @param d 日期
 * @param calcType 向上获取或者向下获取
 */
var timeTypes = ['Hours', 'Minutes', 'Seconds'];
export function getNearestDate(options, extra, d, calcType) {
    var _a = options.hasHour, hasHour = _a === void 0 ? false : _a, _b = options.hasMinute, hasMinute = _b === void 0 ? false : _b, _c = options.hasSecond, hasSecond = _c === void 0 ? false : _c;
    var _d = extra.hourStep, hourStep = _d === void 0 ? 1 : _d, _e = extra.minuteStep, minuteStep = _e === void 0 ? 1 : _e, _f = extra.secondStep, secondStep = _f === void 0 ? 1 : _f;
    var check = function (has, step, timeType) {
        var setter = Date.prototype["set".concat(timeType)];
        var getter = Date.prototype["get".concat(timeType)];
        if (!has) {
            setter.call(d, 0);
        }
        if (step === 1) {
            return;
        }
        var a = getter.call(d);
        var x = a % step;
        if (x > 0) {
            var type = calcType;
            if (type === 'auto') {
                type = x >= step / 2 ? 'plus' : 'minus';
            }
            if (type === 'plus') {
                setter.call(d, a + (step - x));
            }
            else {
                setter.call(d, a - x);
            }
            // 这一项产生了重置，将后面的选项先重置回0
            for (var i = timeTypes.indexOf(timeType) + 1; i < timeTypes.length; i++) {
                Date.prototype["set".concat(timeTypes[i])].call(d, 0);
            }
        }
    };
    check(hasSecond, secondStep, 'Seconds');
    check(hasMinute, minuteStep, 'Minutes');
    check(hasHour, hourStep, 'Hours');
    return d;
}
export function getRealRange(options, extra, validRange) {
    if (!validRange) {
        return undefined;
    }
    return [
        getNearestDate(options, extra, cloneDate(validRange[0]), 'plus'),
        getNearestDate(options, extra, cloneDate(validRange[1]), 'minus'),
    ];
}
export var getDateFormatConf = function (adapterLocale) {
    if (!adapterLocale || !(cnUtils === null || cnUtils === void 0 ? void 0 : cnUtils.getDateFormatConf)) {
        return {
            D: 'YYYY-MM-DD',
            W: 'gggg-wo',
            M: 'YYYY-MM',
            Q: 'YYYY-[Q]Q',
            Y: 'YYYY',
            H: 'YYYY-MM-DD HH:00:00',
            m: 'YYYY-MM-DD HH:mm:00',
            s: 'YYYY-MM-DD HH:mm:ss',
        };
    }
    return cnUtils === null || cnUtils === void 0 ? void 0 : cnUtils.getDateFormatConf(adapterLocale);
};
