import { __assign, __rest } from "tslib";
import $i18n from 'panda-i18n';
import React, { useState } from 'react';
import { useRequest } from 'ahooks';
import { CnCheckbox } from '../cn-checkbox';
import { CnDialog } from '../cn-dialog';
import classNames from 'classnames';
import { handleRequestService } from './service';
import './index.scss';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
/**
 * @category 组件
 *  协议
 */
export var CnAgreement = function (props) {
    var defaultChecked = props.defaultChecked, onChange = props.onChange, labelText = props.labelText, _a = props.requestConfig, requestConfig = _a === void 0 ? {} : _a;
    var _b = useState(defaultChecked), agreementChecked = _b[0], setAgreementChecked = _b[1];
    var _c = useState(false), agreementDialogVisible = _c[0], setAgreementDialogVisible = _c[1];
    React.useEffect(function () {
        if ('checked' in props) {
            setAgreementChecked(props.checked);
        }
    }, [props, props.checked]);
    // 读取requestConfig
    var otherRequestConfig = __rest(requestConfig, []);
    // 请求地址，requestConfig
    var remoteUrl = requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url;
    // 请求服务封装
    var requestService = handleRequestService(requestConfig, remoteUrl);
    // 通过useRequest返回数据
    var _d = useRequest(requestService, __assign({ ready: !!remoteUrl }, otherRequestConfig)).data, data = _d === void 0 ? {} : _d;
    return (React.createElement(React.Fragment, null,
        withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-agreement'), "data-testid": "cn-agreement" },
            React.createElement(CnCheckbox, { checked: agreementChecked, onChange: function (checked) {
                    if (onChange) {
                        if (checked) {
                            onChange(checked);
                        }
                        else {
                            onChange(undefined);
                        }
                    }
                } }),
            React.createElement("div", { 
                // onClick={() => {
                //   setAgreementDialogVisible(true);
                // }}
                className: "cn-ui-m-agreement-text-title" }, labelText))),
        React.createElement(CnDialog, { visible: agreementDialogVisible, closeMode: ['ok', 'cancel'], okProps: {
                children: $i18n.get({ id: 'Agree', dm: '同意', ns: 'CnAgreement' }),
            }, cancelProps: {
                children: $i18n.get({
                    id: 'Disagree',
                    dm: '不同意',
                    ns: 'CnAgreement',
                }),
            }, title: "", footerActions: ['cancel', 'ok'], onOk: function () {
                setAgreementDialogVisible(false);
                if (onChange) {
                    onChange(true);
                }
            }, onCancel: function () {
                setAgreementDialogVisible(false);
                if (onChange) {
                    onChange(false);
                }
            } }, data === null || data === void 0 ? void 0 : data.data)));
};
CnAgreement.defaultProps = {
    defaultChecked: false,
};
CnAgreement.displayName = 'CnAgreement';
