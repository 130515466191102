import * as React from 'react';
import cx from 'classnames';
import { withNativeProps } from '@/utils/with-native-props';
import './cn-status-tag.scss';
/**
 * @category 组件
 *  状态标签
 */
export var CnStatusTag = function (props) {
    var _a;
    var type = props.type, status = props.status, text = props.text, children = props.children;
    var classes = cx(CN_UI_HASH_CLASS_NAME, (_a = {
            'cn-ui-m-status-tag': true
        },
        _a["cn-ui-m-status-tag-".concat(type)] = !!type,
        _a["cn-ui-m-status-tag-status-".concat(status)] = !!status,
        _a));
    return withNativeProps(props, React.createElement("div", { className: classes }, children || text));
};
CnStatusTag.displayName = 'CnStatusTag';
CnStatusTag.defaultProps = {
    type: 'normal',
    status: 'info',
};
