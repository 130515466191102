import { __assign, __rest } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { dayjs } from '@/utils/dayjs';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import { CnCalendar } from '../../../cn-calendar';
import { SelectDrawer } from '@/components/select-drawer';
import { toDate } from '@/utils/date';
import { formatValue, getFormatStrFromPrecision, getValueFromDayjs } from '../../utils';
import { useLatest } from 'ahooks';
import { useLocalization, withNativeProps } from '@cainiaofe/cn-ui-common';
function getInitValue(initValue) {
    return Array.isArray(initValue) ? initValue.map(function (i) { return i || toDate(i); }) : undefined;
}
export var DateRangeCalendar = function (props) {
    var hasClear = props.hasClear, valueProp = props.value, alignProp = props.align, sizeProp = props.size, iconType = props.iconType, placeholder = props.placeholder, disabled = props.disabled, readOnly = props.readOnly, insideForm = props.insideForm, className = props.className, zIndex = props.zIndex, format = props.format, outputFormat = props.outputFormat, defaultValue = props.defaultValue, onChange = props.onChange, onCancel = props.onCancel, onClear = props.onClear, extraFooterRender = props.extraFooterRender, mode = props.mode, insideFilter = props.insideFilter, others = __rest(props, ["hasClear", "value", "align", "size", "iconType", "placeholder", "disabled", "readOnly", "insideForm", "className", "zIndex", "format", "outputFormat", "defaultValue", "onChange", "onCancel", "onClear", "extraFooterRender", "mode", "insideFilter"]);
    var _a = useState(getInitValue(valueProp || defaultValue)), value = _a[0], setValue = _a[1];
    var _b = useState(null), formatValueStr = _b[0], setFormatValueStr = _b[1];
    var _c = useState(defaultValue), pickerValue = _c[0], setPickerValue = _c[1];
    var _d = useState(null), pickerFormatValueStr = _d[0], setPickerFormatValueStr = _d[1];
    var ouputFormatRef = useLatest(outputFormat);
    var adapterLocale = useLocalization('dateAdapterLocale', props.adapterLocale);
    var align = alignProp || 'left';
    var size = sizeProp || 'medium';
    var isControlled = 'value' in props;
    useEffect(function () {
        if (isControlled) {
            var newValue = getInitValue(valueProp || defaultValue);
            if (!newValue || (Array.isArray(newValue) && newValue.every(function (i) { return !i; }))) {
                setPickerValue(null);
                setValue(undefined);
                setPickerFormatValueStr(null);
                setFormatValueStr(null);
                return;
            }
            else if (newValue === value) {
                return;
            }
            setValue(newValue);
            setPickerValue(newValue);
        }
    }, [valueProp, isControlled]);
    var handleCancel = function (reason) {
        setPickerValue(value);
        if (isFunction(onCancel)) {
            onCancel(reason);
        }
    };
    var handleClear = function () {
        if (!isControlled) {
            setPickerValue(null);
            setValue(undefined);
            setFormatValueStr(null);
            setPickerFormatValueStr(null);
        }
        if (isFunction(onClear)) {
            onClear();
        }
        if (onChange) {
            onChange([undefined, undefined], ['', '']);
        }
    };
    var getFormated = useCallback(function (newValue) {
        var formatStr = getFormatStrFromPrecision(mode || 'day', adapterLocale);
        if (!Array.isArray(newValue))
            return undefined;
        return newValue.map(function (i) {
            // format支持函数
            if (isFunction(format)) {
                return format(i);
            }
            return dayjs(i).format(format || formatStr);
        });
    }, [format, adapterLocale]);
    var handleOk = useCallback(function () {
        var newValue = pickerValue;
        if (!newValue)
            return;
        var dayjsValue = newValue.map(function (i) { return dayjs(i); });
        var v = (ouputFormatRef === null || ouputFormatRef === void 0 ? void 0 : ouputFormatRef.current)
            ? formatValue(dayjsValue, ouputFormatRef === null || ouputFormatRef === void 0 ? void 0 : ouputFormatRef.current)
            : getValueFromDayjs(dayjsValue, true, props.endOfDay);
        onChange === null || onChange === void 0 ? void 0 : onChange(v, pickerFormatValueStr || getFormated(newValue));
        if (!isControlled) {
            setValue(newValue);
            setFormatValueStr(pickerFormatValueStr);
        }
    }, [pickerValue, getFormated, props.endOfDay, ouputFormatRef, pickerFormatValueStr]);
    var content = useMemo(function () {
        var formatted = formatValueStr || getFormated(value);
        return Array.isArray(formatted) ? formatted.join(' - ') : null;
    }, [value, getFormated, formatValueStr]);
    if (readOnly) {
        return (React.createElement("span", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-m-date-range-picker-pro-preview') }, content));
    }
    return withNativeProps(props, React.createElement(SelectDrawer, { disableScroll: true, hasClear: hasClear, content: content, align: align, size: size, iconType: iconType, placeholder: placeholder, disabled: disabled, readOnly: readOnly, insideForm: insideForm, className: classNames(className, 'cn-ui-m-date-range-picker-pro'), drawerClassName: "cn-ui-m-date-range-picker-pro-drawer", zIndex: zIndex, onOk: handleOk, onCancel: handleCancel, onClear: handleClear, buttonPosition: "bottom", insideFilter: insideFilter },
        React.createElement(CnCalendar, __assign({}, others, { selectionMode: "range", value: pickerValue, onChange: function (val, dateStr) {
                setPickerValue(val);
                setPickerFormatValueStr(dateStr);
            }, panelMode: mode })),
        extraFooterRender ? (React.createElement("div", { className: "cn-ui-m-date-picker-extra-footer" }, typeof extraFooterRender === 'function' ? extraFooterRender() : extraFooterRender)) : null));
};
DateRangeCalendar.displayName = 'DateRangeCalendar';
DateRangeCalendar.defaultProps = {};
