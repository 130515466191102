import dayjs, { Dayjs } from 'dayjs';
import $i18n, { pandaEvent, langToShortLang } from 'panda-i18n';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import weekday from 'dayjs/plugin/weekday';
import '@/locales/dayjs';
import isBtw from 'dayjs/plugin/isBetween';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekYear from 'dayjs/plugin/weekYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
dayjs.extend(weekday);
dayjs.extend(isBtw);
dayjs.extend(updateLocale);
dayjs.extend(weekYear);
// 基于标准语种返回dayjs的国际化语种
var getDayjsLang = function (lang) {
    var lowLang = lang === null || lang === void 0 ? void 0 : lang.toLowerCase();
    if (lowLang && dayjs.Ls[lowLang]) {
        return lowLang;
    }
    var slang = langToShortLang(lang);
    // 兼容希伯来语(panda-i18n对于希伯来语可能存在2种版本的，dayjs只提供的he的翻译)
    slang = slang === 'iw' ? 'he' : slang;
    // 兼容英文本地语种，为了提高英文本地语种的兼容性，所有英文本地语种全部fallback到en
    slang = slang.startsWith('en-') ? 'en' : slang;
    // 不再支持范围的默认为英文
    return dayjs.Ls[slang] ? slang : 'en';
};
dayjs.locale(getDayjsLang($i18n.getLang()));
pandaEvent.on('CHANGE_LANG', function (_lang) {
    dayjs.locale(getDayjsLang(_lang));
});
function updateDayjsLocale(opts) {
    var lang = getDayjsLang($i18n.getLang());
    dayjs.updateLocale(lang, opts);
}
export { dayjs, Dayjs, updateDayjsLocale };
