import { __assign, __rest } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import './cn-badge.scss';
export var CnBadge = function (props) {
    var _a;
    var className = props.className, showZero = props.showZero, count = props.count, overflowCount = props.overflowCount, children = props.children, _b = props.content, content = _b === void 0 ? '' : _b, shape = props.shape, others = __rest(props, ["className", "showZero", "count", "overflowCount", "children", "content", "shape"]);
    var prefix = 'cn-ui-m-';
    var auto = React.Children.count(children) > 0;
    var sup = count;
    sup = Number(count);
    if (!isNaN(sup) && sup !== 0) {
        sup = sup > (overflowCount || 99) ? "".concat(overflowCount, "+") : sup;
    }
    else {
        // string
        sup = count;
    }
    var badgeChildrenRender = function () {
        var _a, _b, _c, _d;
        // 当showZero为false,count为0时,shape不为dot时, 不展示
        if (showZero === false && sup === 0 && shape !== 'dot') {
            return null;
        }
        if (content) {
            return (React.createElement("div", { className: classNames((_a = {},
                    _a["".concat(prefix, "badge--custom")] = !!content,
                    _a["".concat(prefix, "badge-content--auto")] = auto,
                    _a)) }, content));
        }
        if (shape === 'dot') {
            return (React.createElement("div", { className: classNames((_b = {},
                    _b["".concat(prefix, "badge-content")] = true,
                    _b["".concat(prefix, "badge-content--dot")] = true,
                    _b["".concat(prefix, "badge-content--auto")] = auto,
                    _b)) }));
        }
        if (shape === 'normal') {
            return (React.createElement("div", { className: classNames((_c = {},
                    _c["".concat(prefix, "badge-content")] = true,
                    _c["".concat(prefix, "badge-content--normal")] = true,
                    _c["".concat(prefix, "badge-content--auto")] = auto,
                    _c)) },
                React.createElement("span", { className: "".concat(prefix, "badge-text") }, sup)));
        }
        return (React.createElement("div", { className: classNames((_d = {},
                _d["".concat(prefix, "badge-content")] = true,
                _d["".concat(prefix, "badge-content--normal")] = true,
                _d["".concat(prefix, "badge-content--").concat(shape)] = !content,
                _d)) },
            React.createElement("span", { className: "".concat(prefix, "badge-text") }, sup)));
    };
    return (React.createElement("div", __assign({}, others, { className: classNames(CN_UI_HASH_CLASS_NAME, className, "".concat(prefix, "badge"), "".concat(prefix, "badge--").concat(shape), (_a = {},
            _a["".concat(prefix, "badge--auto")] = auto,
            _a)) }),
        children,
        badgeChildrenRender()));
};
CnBadge.defaultProps = {
    showZero: false,
    shape: 'normal',
    count: 0,
    overflowCount: 99,
};
CnBadge.displayName = 'CnBadge';
