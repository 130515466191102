import { __assign, __extends, __rest } from "tslib";
import * as React from 'react';
import cx from 'classnames';
/**
 * @deprecated
 * 建议使用 CnIcon
 */
var Icon = /** @class */ (function (_super) {
    __extends(Icon, _super);
    function Icon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Icon.prototype.render = function () {
        var _a;
        var _b = this.props, type = _b.type, size = _b.size, className = _b.className, style = _b.style, children = _b.children, rtl = _b.rtl, others = __rest(_b, ["type", "size", "className", "style", "children", "rtl"]);
        var classes = cx(CN_UI_HASH_CLASS_NAME, className, (_a = {
                'cn-ui-m-icon': true
            },
            _a["cn-ui-m-icon-".concat(type)] = !!type,
            _a["cn-ui-m-icon-".concat(size)] = !!size && typeof size === 'string',
            _a));
        var sizeStyle = {};
        return (React.createElement("i", __assign({}, others, { style: __assign(__assign({}, sizeStyle), style), className: classes, "data-type": type }), children));
    };
    Icon.defaultProps = {
        size: 'medium',
    };
    Icon._typeMark = 'icon';
    return Icon;
}(React.Component));
export { Icon };
