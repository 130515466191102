import { __assign, __rest } from "tslib";
import $i18n from 'panda-i18n';
import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { CnIcon } from '../cn-icon';
import { CnPickerView } from '@/components/cn-picker-view';
import { CnButton } from '../cn-button';
import { SelectDrawer } from '@/components/select-drawer';
import { useLocale } from './locale';
import defaultLang from './locale/lang/zh-cn';
// import { isNumber } from '../utils';
// import { useValue } from '../utils/hooks';
import isNumber from 'lodash/isNumber';
import { useValue } from '@/hooks/use-value';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
import './styles/index.scss';
/*
  param totalPage：number 总页面数
  return pageNumberList:array 页码数量
*/
var getPageNumber = function (totalPage) {
    var pageNumberList = [];
    for (var i = 1; i <= totalPage; i++) {
        pageNumberList.push({
            label: i,
            value: i,
        });
    }
    return [pageNumberList];
};
/**
 * 根据 current, totalPage 计算当前有效页数
 * @param current
 * @param totalPage
 * @param defaultValue
 */
var getCurPageNum = function (current, totalPage, defaultValue) {
    if (defaultValue === void 0) { defaultValue = 1; }
    if (isNumber(current)) {
        if (current >= 1 && current <= totalPage) {
            return current;
        }
        else if (current > totalPage) {
            return totalPage;
        }
        else if (current < 1) {
            return defaultValue;
        }
    }
    else {
        return defaultValue;
    }
};
var selectedValue = 1;
var Pagination = function (props, ref) {
    var _a;
    var _b = props.size, size = _b === void 0 ? 'medium' : _b, _c = props.type, typeProp = _c === void 0 ? 'normal' : _c, defaultCurrent = props.defaultCurrent, totalProp = props.total, totalCountProp = props.totalCount, _d = props.pageSize, pageSizeProp = _d === void 0 ? 10 : _d, _e = props.isOpenDisabled, isOpenDisabled = _e === void 0 ? true : _e, previousButtonText = props.previousButtonText, nextButtonText = props.nextButtonText, _f = props.onChange, onChange = _f === void 0 ? function () { } : _f, _g = props.prefix, prefix = _g === void 0 ? 'cn-ui-m-' : _g, others = __rest(props, ["size", "type", "defaultCurrent", "total", "totalCount", "pageSize", "isOpenDisabled", "previousButtonText", "nextButtonText", "onChange", "prefix"]);
    var type = typeProp === 'simple' ? 'normal' : typeProp;
    var locale = useLocale('CnPagination', others, defaultLang.CnPagination);
    var pageSize = isNumber(pageSizeProp) && pageSizeProp > 0 ? pageSizeProp : 1;
    var _total = (_a = (totalCountProp || totalProp)) !== null && _a !== void 0 ? _a : 100;
    var total = isNumber(_total) && _total > 0 ? _total : 1;
    var totalPage = Math.ceil(total / pageSize) || 1;
    var pageNumberData = getPageNumber(totalPage);
    var _h = useValue(props, 1, {
        valueName: 'current',
        fitValue: function (v) {
            return getCurPageNum(v, totalPage);
        },
    }), currentPageValue = _h[0], setCurrentPageValue = _h[1], isControlled = _h[2];
    selectedValue = currentPageValue;
    // 是否开启按钮disabled
    var previousButtonDisabled = currentPageValue === 1 && isOpenDisabled;
    var nextButtonDisabled = currentPageValue === totalPage && isOpenDisabled;
    // 样式
    var clsPrefix = "".concat(prefix, "pagination");
    var classNameObj = {
        pagination: classNames(CN_UI_HASH_CLASS_NAME, clsPrefix, "".concat(clsPrefix, "--").concat(size)),
        paginationTextMini: classNames("".concat(clsPrefix, "--").concat(size, "-text-mini")),
        paginationText: classNames("".concat(clsPrefix, "-text"), "".concat(clsPrefix, "--").concat(size, "-text")),
        paginationNumber: classNames("".concat(clsPrefix, "--").concat(size, "-text-number")),
        paginationTextCurrent: classNames("".concat(clsPrefix, "-text-current"), "".concat(clsPrefix, "--").concat(size, "-text-number")),
        paginationTextSelectDrawer: classNames("".concat(clsPrefix, "-text-select-drawer")),
        paginationIconFirst: classNames("".concat(clsPrefix, "-icon"), "".concat(clsPrefix, "--").concat(size, "-icon"), "".concat(clsPrefix, "-icon--first"), "".concat(clsPrefix, "--").concat(size, "-icon--first"), previousButtonDisabled ? "".concat(clsPrefix, "-icon--disabled") : ''),
        paginationIconLast: classNames("".concat(clsPrefix, "-icon"), "".concat(clsPrefix, "--").concat(size, "-icon"), "".concat(clsPrefix, "-icon--last"), "".concat(clsPrefix, "--").concat(size, "-icon--last"), nextButtonDisabled ? "".concat(clsPrefix, "-icon--disabled") : ''),
    };
    // 上一页
    var handlePreviousButton = function (e) {
        if (currentPageValue <= 1) {
            return;
        }
        var prePageValue = currentPageValue - 1;
        if (!isControlled) {
            setCurrentPageValue(prePageValue);
        }
        if (onChange) {
            onChange(prePageValue, e);
        }
    };
    // 下一页
    var handleNextButton = function (e) {
        if (currentPageValue >= totalPage) {
            return;
        }
        var nextPageValue = currentPageValue + 1;
        if (!isControlled) {
            setCurrentPageValue(nextPageValue);
        }
        if (onChange) {
            onChange(nextPageValue, e);
        }
    };
    // 确定点击
    var handleOk = function () {
        if (!isControlled) {
            setCurrentPageValue(selectedValue);
        }
        if (onChange) {
            onChange(selectedValue);
        }
    };
    // 取消按钮点击
    var handleCancel = function () {
        selectedValue = currentPageValue;
        if (!isControlled) {
            setCurrentPageValue(selectedValue);
        }
    };
    // 选中内容
    var changePageNumber = function (item) {
        selectedValue = item[0];
    };
    return withNativeProps(props, React.createElement("div", __assign({}, others, { className: classNameObj.pagination, ref: ref }),
        React.createElement(CnButton, { size: size, model: "outline", disabled: previousButtonDisabled, onClick: handlePreviousButton },
            React.createElement(CnIcon, { type: "arrow-left", className: classNameObj.paginationIconFirst }),
            React.createElement("span", null, previousButtonText ||
                $i18n.get({ id: 'PreviousPage', dm: '上一页', ns: 'CnPagination' }))),
        type === 'normal' ? (React.createElement("div", null,
            React.createElement(SelectDrawer, { content: React.createElement("div", { className: classNameObj.paginationText },
                    React.createElement("span", { className: classNameObj.paginationTextCurrent }, currentPageValue),
                    React.createElement("span", { className: classNameObj.paginationNumber },
                        "/",
                        totalPage)), className: classNameObj.paginationTextSelectDrawer, placeholder: "", iconType: "", size: "small", drawerTitle: locale.tip, onOk: handleOk, onCancel: handleCancel, style: { width: '100%' }, buttonPosition: "bottom" },
                React.createElement(CnPickerView, { columns: pageNumberData, defaultValue: [currentPageValue], onChange: changePageNumber })))) : (React.createElement("div", { className: classNameObj.paginationTextMini })),
        React.createElement(CnButton, { size: size, model: "outline", disabled: nextButtonDisabled, onClick: handleNextButton },
            React.createElement("span", null, nextButtonText || $i18n.get({ id: 'NextPage', dm: '下一页', ns: 'CnPagination' })),
            React.createElement(CnIcon, { type: "arrow-right", size: size, className: classNameObj.paginationIconLast }))));
};
Pagination.displayName = 'CnPagination';
export var CnPagination = forwardRef(Pagination);
