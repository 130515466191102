import { useLayoutEffect, useRef, useState } from 'react';
export var useIsInCard = function () {
    var conRef = useRef(null);
    var _a = useState(undefined), isInCard = _a[0], setIsInCard = _a[1];
    useLayoutEffect(function () {
        if (!(conRef === null || conRef === void 0 ? void 0 : conRef.current))
            return;
        var find = function (dom) {
            var _a, _b, _c;
            if (!dom)
                return false;
            if (((_a = dom.classList) === null || _a === void 0 ? void 0 : _a.contains('cn-ui-m-card'))
                || ((_b = dom.classList) === null || _b === void 0 ? void 0 : _b.contains('cn-tile'))
                || ((_c = dom.classList) === null || _c === void 0 ? void 0 : _c.contains('cn-ui-m-base-card'))) {
                return true;
            }
            return find(dom.parentNode);
        };
        setIsInCard(find(conRef.current));
    }, []);
    return { isInCard: isInCard, conRef: conRef };
};
