import { __assign } from "tslib";
import { isPlainObject } from 'lodash';
import { workNoPadZero } from '@cainiaofe/cn-ui-common';
import $i18n from 'panda-i18n';
/**
 * 处理员工数据源
 * @param dataSource
 * @returns 返回key为工号的map数据
 */
export function transformToEmployeeMap(dataSource) {
    var employeeMap = {};
    dataSource.forEach(function (employeeItem) {
        if (employeeItem.workNo) {
            if (!employeeMap[employeeItem.workNo]) {
                employeeMap[employeeItem.workNo] = employeeItem;
            }
        }
    });
    return employeeMap;
}
export function getFormattedValue(value, valuePaddingZero, useDetailValue) {
    if (!value) {
        return undefined;
    }
    var list = Array.isArray(value) ? value : [value];
    return list.reduce(function (acc, item) {
        if (typeof item === 'string' || typeof item === 'number') {
            var workNo = valuePaddingZero ? workNoPadZero("".concat(item)) : "".concat(item);
            acc.push(useDetailValue ? { value: workNo } : workNo);
        }
        else if (isPlainObject(item)) {
            var workNo = "".concat(item.value || item.workNo || '');
            acc.push(__assign(__assign({}, item), { value: valuePaddingZero ? workNoPadZero(workNo) : workNo }));
        }
        return acc;
    }, []);
}
export var getRenderEmployee = function (employee) {
    if ($i18n.getLang() === 'zh-CN') {
        return employee.label || employee.nickName || employee.name || employee.value;
    }
    return employee.name || employee.label || employee.nickName || employee.value;
};
