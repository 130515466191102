import { getDateFormatConf } from '@/utils/date';
/**
 * 根据精度返回format字符串
 * @param precision 精度
 * @returns formatStrig
 */
export var getFormatStrFromPrecision = function (precision, adapterLocale) {
    var i18nFormats = getDateFormatConf(adapterLocale);
    if (precision === 'day') {
        return i18nFormats.D;
    }
    else if (precision === 'hour') {
        return i18nFormats.H;
    }
    else if (precision === 'minute') {
        return i18nFormats.m;
    }
    else if (precision === 'second') {
        return i18nFormats.s;
    }
    else if (precision === 'month') {
        return i18nFormats.M;
    }
    else if (precision === 'year') {
        return i18nFormats.Y;
    }
    else if (precision === 'week') {
        return i18nFormats.W;
    }
    else if (precision === 'week-day') {
        return 'gggg-wo-ddd';
    }
    else if (precision === 'quarter') {
        return i18nFormats.Q;
    }
    else {
        return i18nFormats.D;
    }
};
