import React, { forwardRef, useCallback, useEffect, useMemo, } from 'react';
import { useMemoizedFn } from 'ahooks';
import { CnPickerView } from '@/components/cn-picker-view';
import { withNativeProps } from '@/utils/with-native-props';
import { mergeProps } from '@/components/cn-picker/with-default-props';
import { usePropsValue } from '@/utils/use-props-value';
import { convertDateToStringArray, convertStringArrayToDate, defaultI18nRenderLabel, generateDatePickerColumns, } from '../utils/date-picker-utils';
import { bound } from '@/utils/bound';
import { toDate } from '@/utils/date';
var thisYear = new Date().getFullYear();
var defaultProps = {
    min: new Date(new Date().setFullYear(thisYear - 10)),
    max: new Date(new Date().setFullYear(thisYear + 10)),
    precision: 'day',
    renderLabel: defaultI18nRenderLabel,
    defaultValue: null,
};
var CnDatePickerView = forwardRef(function (p, ref) {
    var props = mergeProps(defaultProps, p);
    var _a = usePropsValue({
        value: props.value,
        defaultValue: props.defaultValue,
        onChange: function (v) {
            var _a;
            if (v === null)
                return;
            (_a = props.onConfirm) === null || _a === void 0 ? void 0 : _a.call(props, v);
        },
    }), value = _a[0], setValue = _a[1];
    var now = useMemo(function () { return new Date(); }, []);
    var pickerValue = useMemo(function () {
        var date = value ? toDate(value) : now;
        date = new Date(bound(date.getTime(), props.min.getTime(), props.max.getTime()));
        return convertDateToStringArray(date, props.precision);
    }, [value, props.precision, props.min, props.max]);
    var onConfirm = useCallback(function (val) {
        setValue(convertStringArrayToDate(val, props.precision), true);
    }, [setValue, props.precision]);
    var onSelect = useMemoizedFn(function (val) {
        var _a;
        var date = convertStringArrayToDate(val, props.precision);
        (_a = props.onSelect) === null || _a === void 0 ? void 0 : _a.call(props, date);
    });
    var columns = useCallback(function (selected) {
        return generateDatePickerColumns(selected, props.min, props.max, props.precision, props.renderLabel, props.filter);
    }, [props.min, props.max, props.precision, props.renderLabel]);
    // 初次渲染时触发一次onSelect，保证用户未操作直接点确认时值的正确性
    useEffect(function () {
        var _a;
        var date = convertStringArrayToDate(pickerValue, props.precision);
        (_a = props.onSelect) === null || _a === void 0 ? void 0 : _a.call(props, date);
    }, []);
    return withNativeProps(props, React.createElement(CnPickerView, { ref: ref, columns: columns, value: pickerValue, onCancel: props.onCancel, onClose: props.onClose, closeOnMaskClick: props.closeOnMaskClick, visible: props.visible, confirmText: props.confirmText, cancelText: props.cancelText, onConfirm: onConfirm, onChange: onSelect, getContainer: props.getContainer, loading: props.loading, loadingContent: props.loadingContent, afterShow: props.afterShow, afterClose: props.afterClose, onClick: props.onClick, title: props.title, stopPropagation: props.stopPropagation, mouseWheel: props.mouseWheel, destroyOnClose: props.destroyOnClose, forceRender: props.forceRender }, function (_, actions) { var _a; return (_a = props.children) === null || _a === void 0 ? void 0 : _a.call(props, value, actions); }));
});
CnDatePickerView.displayName = 'CnDatePickerView';
export { CnDatePickerView };
