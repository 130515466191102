import { __spreadArray } from "tslib";
import $i18n from 'panda-i18n';
import React, { useEffect, useState } from 'react';
import { CnSafeArea } from '@/components/cn-safe-area';
import { CnDrawer } from '@/components/cn-drawer';
import { CnList } from '@/components/cn-list';
import { findInArray } from '@/utils/func';
import Option from '../option';
import { removeItemFromArray } from '../util';
import { CnIcon } from '@/components/cn-icon';
export default function SelectedListDrawer(props) {
    var prefix = props.prefix, innerValue = props.innerValue, renderItem = props.renderItem;
    var visible = props.visible, onOk = props.onOk, onCancel = props.onCancel;
    var clsPrefix = "".concat(prefix, "select");
    var _a = useState([]), selectedList = _a[0], setSelectedList = _a[1];
    var handleOk = function () { return onOk(selectedList); };
    var handleItemClick = function (item) {
        var isSelected = findInArray(selectedList, function (it) { return it.value === item.value; });
        if (isSelected) {
            var arr = removeItemFromArray(selectedList, item.value);
            setSelectedList(arr);
        }
        else {
            setSelectedList(__spreadArray(__spreadArray([], selectedList, true), [item], false));
        }
    };
    useEffect(function () { return setSelectedList(innerValue); }, [innerValue]);
    return (React.createElement(CnDrawer, { visible: visible, closeMode: ['mask'], className: "".concat(clsPrefix, "-confirm-drawer"), containerClassName: "".concat(clsPrefix, "-confirm-drawer-container"), onClose: onCancel, noCard: true },
        React.createElement("div", { className: "".concat(clsPrefix, "-confirm-drawer-header") },
            React.createElement("span", { className: "".concat(clsPrefix, "-confirm-drawer-header-total") }, $i18n.get({
                id: 'TheSelectedListLengthItemIsCurrentlySelected_1841163092',
                dm: '当前已选中 {selectedListLength} 项',
                ns: 'CnSelect',
            }, { selectedListLength: selectedList.length })),
            React.createElement("div", { className: "".concat(clsPrefix, "-confirm-drawer-header-close"), onClick: handleOk },
                React.createElement(CnIcon, { type: "close", size: "small" }))),
        React.createElement("div", { className: "".concat(clsPrefix, "-confirm-drawer-body") },
            React.createElement(CnList, null, innerValue.map(function (item) {
                var selected = findInArray(selectedList, function (it) { return it.value === item.value; });
                return (React.createElement(Option, { selected: selected, key: item.value, onClick: function () { return handleItemClick(item); } }, typeof renderItem === 'function'
                    ? renderItem(item)
                    : item.label));
            }))),
        React.createElement(CnSafeArea, { className: "".concat(clsPrefix, "-safe-area") })));
}
SelectedListDrawer.displayName = 'SelectedListDrawer';
