import { useFieldSchema } from '@formily/react';
export var useOperateColumn = function () {
    var schema = useFieldSchema();
    var findOperateColumn = function (_schema) {
        // 移动端暂时不支持数组类型的操作列配置
        if (Array.isArray(_schema)) {
            return [];
        }
        var name = _schema.name, properties = _schema.properties;
        if (name === 'operateColumn') {
            return [_schema];
        }
        if (properties) {
            return _schema.reduceProperties(function (result, schemaItem) {
                return result.concat(findOperateColumn(schemaItem));
            }, []);
        }
        return [];
    };
    return findOperateColumn(schema.items)[0];
};
