import { __assign, __awaiter, __generator, __rest } from "tslib";
import React, { useEffect, useState } from 'react';
import { langList as allLangList, pandaEvent, useI18n, } from 'panda-i18n';
import keyBy from 'lodash/keyBy';
import { CnSelect } from '@/components/cn-select';
import { CnIcon } from '@/components/cn-icon';
import classNames from 'classnames';
var allLangMap = keyBy(allLangList, 'value');
var classPrefix = 'cn-ui-m-i18n-select';
var defaultLangList = allLangList.slice(0, 7);
var CnI18nSelect = function (_a) {
    var lang = _a.lang, langList = _a.langList, onChange = _a.onChange, beforeChange = _a.beforeChange, className = _a.className, inverse = _a.inverse, props = __rest(_a, ["lang", "langList", "onChange", "beforeChange", "className", "inverse"]);
    var $i18n = useI18n();
    var _b = useState(lang || $i18n.getLang()), currentLang = _b[0], setCurrentLang = _b[1];
    var usedLangList = (langList === null || langList === void 0 ? void 0 : langList.map(function (_lang) {
        return allLangMap[_lang] || {
            value: _lang,
            label: _lang,
        };
    })) || defaultLangList;
    // lister lang changed then change the current lang
    useEffect(function () {
        lang && setCurrentLang(lang);
        var changeLangHandler = pandaEvent.on('CHANGE_LANG', function (_lang) {
            if (!lang) {
                setCurrentLang(_lang);
            }
        });
        return function () { return pandaEvent.off(changeLangHandler); };
    }, [lang]);
    return (React.createElement(CnSelect, __assign({ className: classNames(CN_UI_HASH_CLASS_NAME, classPrefix, className), value: currentLang, dataSource: usedLangList, onChange: function (v) { return __awaiter(void 0, void 0, void 0, function () {
            var result, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(beforeChange && typeof beforeChange === 'function')) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, beforeChange(v)];
                    case 2:
                        result = _a.sent();
                        if (result === false) {
                            return [2 /*return*/];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        return [2 /*return*/];
                    case 4:
                        $i18n.setLang(v);
                        if (onChange) {
                            onChange(v);
                        }
                        else {
                            location.reload();
                        }
                        return [2 /*return*/];
                }
            });
        }); }, showIcon: false, renderSelection: function () {
            var _a;
            return (React.createElement("div", { className: classNames("".concat(classPrefix, "-icon"), (_a = {},
                    _a["".concat(classPrefix, "-inverse")] = inverse,
                    _a)) },
                React.createElement(CnIcon, { type: "international1", size: "medium" })));
        } }, props)));
};
export { CnI18nSelect };
