import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import { CnDatePickerPro } from './cn-date-picker-pro';
var CnYearPickerPro = function (props) {
    return (React.createElement(CnDatePickerPro, __assign({ precision: "year", placeholder: $i18n.get({
            id: 'PleaseSelectTheYear',
            dm: '请选择年',
            ns: 'CnDatePickerPro',
        }) }, props)));
};
CnYearPickerPro.displayName = 'CnYearPickerPro';
export { CnYearPickerPro };
